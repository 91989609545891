import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import AccountContent from "../../components/AccountContent"
import { Link } from "gatsby"
import "./my-account.scss"
import { gql, useQuery } from "@apollo/client"
import { isUserLoggedIn } from "../../utils/function"

const USER_DATA = gql`
query($customerId: Int) {
  customer(customerId: $customerId) {
    firstName
    lastName
    username
    displayName
    email
  }
}
`
const auth =isUserLoggedIn()
const customerId= auth!==null?auth.userID:null

function EditAccount(props) {
  const [userDetail, setUserDetail] = useState([])
  const { error, loading, data, refetch } = useQuery(USER_DATA, {
    variables: {
      id: customerId
    }
  })
  // if (loading) return 'Loading...'
  // if (error) return `Submition error! ${error.message}`
  useEffect(() => {
    if (auth) {
      refetch()
    }
  }, [auth])
  useEffect(() => {
    if (data) {
      setUserDetail(data)
    }
  }, [data])
  
  return (
    <Layout>
      <div className="section my-account">
        <div className="container pd-row">
          <AccountContent>
            <div className="my-account__edit">
              <form className="login__form">
                <div className="input-wrapper full-mb half">
                  <label htmlFor="">
                    First name
                    <abbr title="required">*</abbr>
                  </label>
                  <input type="text" name="" id="" required value={loading ? "loadding..." : userDetail?.customer?.firstName} />
                </div>
                <div className="input-wrapper full-mb half">
                  <label htmlFor="">
                    Last name
                    <abbr title="required">*</abbr>
                  </label>
                  <input type="text" name="" id="" required value={loading ? "loadding..." : userDetail?.customer?.lastName} />
                </div>
                <div className="input-wrapper full-mb">
                  <label htmlFor="">
                    Display name
                    <abbr title="required">*</abbr>
                  </label>
                  <input type="text" name="" id="" required value={loading ? "loadding..." : userDetail?.customer?.displayName} />
                  <span>
                    <em>This will be how your name will be displayed in the account section and in reviews</em>
                  </span>
                </div>
                <div className="input-wrapper full-mb">
                  <label htmlFor="">
                    Email address
                    <abbr title="required">*</abbr>
                  </label>
                  <input type="text" name="" id="" required value={loading ? "loadding..." : userDetail?.customer?.email} />
                </div>
                <fieldset>
                  <legend>Password change</legend>
                  <div className="input-wrapper full-mb">
                    <label htmlFor="">
                      Current password (leave blank to leave unchanged)
                    </label>
                    <input type="password" name="" id="" />
                  </div>
                  <div className="input-wrapper full-mb">
                    <label htmlFor="">
                      New password (leave blank to leave unchanged)
                    </label>
                    <input type="password" name="" id="" />
                  </div>
                  <div className="input-wrapper full-mb">
                    <label htmlFor="">
                      Confirm new password
                    </label>
                    <input type="password" name="" id="" />
                  </div>
                </fieldset>
                <div className="input-wrapper input-wrapper--submit">
                  <button type="submit" value="Save changes">Save changes</button>
                </div>
              </form>
            </div>
          </AccountContent>
        </div>
      </div>
    </Layout>
  )
}

export default EditAccount